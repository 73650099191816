<template>
  <v-dialog v-if="typeof dialog!=='undefined'" v-model="loading" :width="96" persistent>
    <v-sheet class="position-relative d-flex align-center" :width="96" :height="96">
      <v-icon class="position-absolute d-block mx-auto" style="margin-left:calc(50% - 12px)!important" color="primary">{{icon}}</v-icon>
      <v-progress-circular class="d-block mx-auto" color="primary" indeterminate size="46"/>
    </v-sheet>
  </v-dialog>
  <v-sheet v-else class="position-relative d-flex align-center ma-auto" :width="96" :height="96" color="transparent">
    <v-icon class="position-absolute d-block mx-auto" style="margin-left:calc(50% - 12px)!important" color="primary">{{icon}}</v-icon>
    <v-progress-circular class="d-block mx-auto" color="primary" :indeterminate="typeof loading==='undefined' || loading===true" value="0" size="46"/>
  </v-sheet>
</template>

<script>
export default {
  props: ['loading', 'icon', 'dialog']
}
</script>
